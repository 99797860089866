import React from "react";
import { Container, Box, Button, Flex, Heading } from "theme-ui";
import backgroundImage from "./images/kj-bg.png";
import profileImage from "./images/profile.png";
import { SocialIcon } from "react-social-icons";
import { theme } from "./createTheme.tsx";
import { Image } from "theme-ui";

function App() {
  const socialLinks = [
  ];

  const links = [
    { title: "Twitch", link: "https://twitch.tv/akakayjay" },
    { title: "Twitter", link: "https://x.com/kayjay_thefusky" },
    { title: "BlueSky", link: "https://bsky.app/profile/akakayjay.bsky.social" },
    { title: "Telegram", link: "https://t.me/akakayjay" },
    { title: "Discord", link: "https://discord.gg/eryvevJF5h" },
    { title: "Steam", link: "https://steamcommunity.com/id/Kayjayfusky" },
    { title: "VRChat - Kayjay_fusky", link: null },
  ];

  const title = "KayJay";

  function openLink(url: string) {
    window.open(url, "_blank");
  }
  return (
    <Container
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundAttachment: "fixed",
        height: "100%",
        minHeight: "100vh",
        pt: 4,
      }}
    >
      <Heading sx={{ textAlign: "center" }} mb={3}>
        {title}
      </Heading>
      <Image src={profileImage} variant="avatar" mb={4} sx={{}} />
      {socialLinks.length > 0 && (
      <Flex
        sx={{
          marginX: "auto",
          justifyContent: "center",
        }}
      >
        {socialLinks.map((x, i) => {
          return (
            <Box key={i} sx={{ display: "inline-block" }} mx={2}>
              <SocialIcon
                url={x.link}
                bgColor={theme?.colors?.primary}
                fgColor={theme?.colors?.background}
              />
            </Box>
          );
        })}
      </Flex>
      )}
      <Flex
        sx={{
          maxWidth: "300px",
          marginX: "auto",
          justifyContent: "center",
          flexDirection: "column",
          mt: 4,
        }}
      >
        {links.map((x, i) => {
          return (
            <Button
              key={i}
              sx={{
                mt: 3,
                "&:first-of-type": {
                  mt: 0,
                },
              }}
              onClick={() => x.link && openLink(x.link)}
            >
              {x.title}
            </Button>
          );
        })}
      </Flex>
    </Container>
  );
}

export default App;
